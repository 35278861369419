<template style="background-color: white">
  <div id="app-university-category" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="subject" :rules="rules"  label-width="100px">
      <el-form-item label="所属科目" >
        <el-select v-model="subject.parentId" placeholder="请选择" style="width:100%">
          <el-option
              v-for="subject in subjects"
              :key="subject.id"
              :label="subject.name"
              :value="subject.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="科目名称" prop="name">
        <el-input v-model="subject.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="科目排序" prop="sort">
        <el-input type="number" v-model="subject.sort" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="科目图片" prop="picUrl">
        <el-upload :action="uploadUrl" :show-file-list="false" :accept="'image/*'" :on-success="handleSuccess">
          <el-image style="width: 100px; height: 100px" :src="subject.picUrl" v-show="subject.picUrl" fit="fill"></el-image>
          <el-button type="primary" v-show="!subject.picUrl">上传 <i class="el-icon-upload el-icon-right"></i></el-button>
          <el-button type="primary" v-show="subject.picUrl" style="position: relative; top:-45px; left:20px" >上传 <i class="el-icon-upload el-icon-right"></i></el-button>
        </el-upload>
      </el-form-item>

      <el-form-item style="display: none">
        <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {UrlEnum,TipEnum} from "../../../../public/js/common-vue";
import {getLocalProjectId} from "../../../../public/js/common-vue";
import {request} from "../../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  components: {Loading},
  data() {
    return {
      name: "Add.vue",
      subject:{
        parentId: request("parentId") == '0' ? '' : parseInt(request("parentId")),
        name:'',
        sort:'',
        picUrl:'',
        projectId:getLocalProjectId()
      },
      rules:{
        name :[{required: true, message:'请输入', trigger:'blur'}],
        sort :[{required: true, message:'请输入', trigger:'blur'}],
        picUrl: [{required: true,message:'请上传科目图片',trigger:'blur'}]
      },
      subjects:[],
      uploadUrl:UrlEnum.UPLOAD_PIC,
      loading: false
    }
  },
  methods: {
    handleSuccess(response, file, fileList) {
      console.log(response);
      this.subject.picUrl=response.data.src;
    },
    handleError() {
      this.showMsgError("上传失败,请重新上传图片!");
    },
    handleBeforeUpload(file) {
      const isImage = file.type.includes("image");
      if (!isImage) {
        this.showMsgError("上传文件类型必须是图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.showMsgError("上传图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
    handleProgress(event, file, fileList) {
      this.loading = true;  //  上传时执行loading事件
    },
    submit: function (formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.loading = true;
          var data = {};
          Object.assign(data,this.subject);
          if(data.parentId == ''){
            data.parentId = 0;
          }
          this.$http({
            method: "post",
            url: UrlEnum.SUBJECT,
            data: data,
          })
              .then((res) => {
                this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
                const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
                setTimeout(function () {
                  parent.layui.table.reload('tableData'); //重载表格
                  parent.layer.close(index); //再执行关闭
                }, 800);
              })
              .catch((res) => {
                this.loading = false;
                if(res.response != null){
                  const data = res.response.data;
                  this.showMsgError(data.message);
                }
              });
        }
      })
    },
    getSubjects:function(){
      var that = this;
      this.$http({
        method: "get",
        url: UrlEnum.SUBJECT + '?parentId=0&page=1&size=100&projectId='+getLocalProjectId(),
      }).then((res) => {
        this.subjects = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  },
  mounted() {
    this.getSubjects();
    console.log(this.subject);
  }
}
</script>

<style scoped>
@import "../../../../static/css/add.css";
</style>
